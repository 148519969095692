.cont-button-whats {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  margin: 5px;
  border-radius: 50%;
  background-color: rgb(37, 181, 37);
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  z-index: 9999;
  position: fixed;
  bottom: 15px;
  right: 25px;
  cursor: pointer;
}



.cont-button-whats a {
  color: #ffffff;
  font-size: 39px;
}

@media (max-width: 550px) {
  .cont-button-whats {
    right: 18px;
    bottom: 20px;
    z-index: 9999;
    opacity: 0.5;
  }
}


@media (max-width: 435px){
  .cont-button-whats{
      bottom: 70px;
      right: 35px;
      opacity: 0.5;
  }
}