.especializaciones {
  font-family: "Sedan SC", serif;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  margin: 60px;
  margin-top: 50px;
  
}

@media (max-width: 700px) {
    .especilizaciones{
        margin-bottom:1000px;
    }
}
