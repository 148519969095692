.cont-banner {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center; /* Ajusta esto según tus necesidades */
  align-items: center; /* Alinea verticalmente si los elementos tienen diferentes alturas */

  background: #c5b29f;
  color: #fff;
}

.cont-banner div {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-banner div h1 {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  position: relative;
  height: 100%;
  top: 15px;
}
.cont-banner div h2,
.cont-banner div h3 {
  font-size: 24px;
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  position: relative;
  height: 100%;
  top: 15px;
}

.cont-banner div {
  text-align: center;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .cont-banner div h1,
  .cont-banner div h2 {
    height: 100%;
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }

  .cont-banner {
    height: 120px;

    background: #978675;
  }
}
