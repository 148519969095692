.container-slug {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 190px;
  padding-top: 130px;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.header-slug {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.nav-breadcrumb {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -30px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ababab85;
}
.breadcrumb {
  width: 100%;
  display: flex;
  padding: 0;
  font-size: 14px;
}

.breadcrumb-item {
  margin-right: -25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
  color: #000;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.titulo-slug {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  margin: 10px 0;
  text-align: start;
}

.fecha-slug {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #9c9c9c;
}

.cont-texto-slug {
  width: 60%;
  margin-top: 20px;
}

.titulo-texto-slug {
  font-size: 19px;
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 1px solid #ababab85;
  color: #000;
  text-align: start;
}

.texto-slug {
  font-weight: 200;
  color: #000;
  text-align: start;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-size: 15px;
  font-style: normal;
  margin-bottom: 10px;
}

.cont-imagenes-info {
  width: 35%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.imagen-slug {
  width: 100%;
  margin-bottom: 20px;
  height: auto;
  object-fit: contain;
}

.contact-slug {
  width: 100%;
  margin-top: 50px;
}

.contact-slug-comment {
  width: 100%;
  margin-top: 20px;
}

.ver-mas-noticias {
  margin: 50px auto 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.titulo-ver-mas {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.cont-mas-noticia {
  width: auto;
display: flex;
  flex-direction: row;
  border-radius: 2px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.cont-mas-noticia:hover {
  transform: translateY(-10px);
}
.link-slug{
  text-decoration: none;
  color: #ffffff;
  background-color: rgb(181, 157, 131);
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  position: relative;
  top: 20px;
}
.imagen-mas-noticia {
  width: 300px;
  height: 290px;
  object-fit: contain;
}

.titulo-noticia {
  text-align: center;
  padding: 5px;
  font-size: 13px;
  color: #000000;
}

.breadcrumb-item::after {
  content: "/";
  margin-left: 10px;
}

.breadcrumb-item:last-child::after {
  content: "";
}

@media screen and (max-width: 768px) {
  .nav-breadcrumb {
    width: 100%;
  }
  .breadcrumb {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-left: -35px;
  }
  .contact-slug {
    margin-bottom: 75px;
  }
  .container-slug {
    padding-top: 100px;
    margin-bottom: 100px;
    width: 95%;
  }
  .titulo-slug {
    font-size: 30px;
    text-align: justify;
  }
  .titulo-texto-slug {
    margin-top: 15px;
    font-size: 20px;
  }
  .fecha-slug {
    font-size: 10px;
    margin-bottom: 25px;
  }
  .texto-slug {
    margin-top: 15px;
    text-align: justify;
  }

  .cont-texto-slug {
    width: 100%;
    margin: auto;
    margin-bottom: 25px;
  }

  .cont-imagenes-info {
    width: 100%;
    margin-left: 0;
  }

  .ver-mas-noticias {
    margin-top: 30px;
  }

  .cont-mas-noticia {
    width: 100%;
  }
}
