.container-section-presentacion {
  margin-top: 90px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 700px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.476);
}
.presentacion-descripcion {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.logo-presentacion {
  width: 250px;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px;
  position: relative;
  top: 10px;
  left: -40px;
}

/* .title-portada {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;

  border-bottom: 2px solid #00000016;
  padding-bottom: 9px;
  text-align: center;
  width: auto;
}
 */
.cont-img-presentacion {
  width: 50%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.img-presentacion {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.cont-info-presentacion {

  color: rgb(0, 0, 0);
  width: 50%;
  padding: 15px;
  padding-right: 60px;

  margin: 15px;

  text-align: start;
}

.cont-info-presentacion h1  {
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
  margin-top: 10px;
  text-align: center;
}
.cont-info-presentacion h2{
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.cont-info-presentacion p {
  width: 100%;
  font-weight: 400;
  text-align: justify;
  font-style: normal;
  font-size: 16px;
  margin-top: 10px;
}

.cont-info-presentacion button {
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #d9c9ba;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

@media (max-width: 600px) {
  .cont-info-presentacion h1 {
    font-size: 30px;

    border-bottom: 1px solid black;
    text-align: start;

    width: 100%;
  }
  .cont-info-presentacion h2{
    text-align: start;
  }
  .container-section-presentacion {
    margin-top: 850px;
    width: 100%;
    flex-direction: column;
  }
  .cont-img-presentacion {
    width: 100%;
  }
  .cont-info-presentacion {
    width: 100%;
    text-align: center;
  }

}
