.portada-principal-p {
  background-image: url("../../assets/portadaoscura.png");

  height: 750px; /* Ajusta la altura de la portada */
  /* Ajusta la imagen de fondo */
  background-size: cover;

  /* Posiciona la imagen en el centro */ /* Ajusta el brillo de la imagen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_portada {
  width: 300px;
  height: 300px;
}

.container-info-portada-p {
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin-top: 10px;
  align-items: flex-end;
  width: 80%;
  height: 500px; /* Ajusta la altura del contenedor */
  flex-direction: column;
  /* Ajusta el color de fondo del contenedor */
  transform: translate(-100%);
  color: #ffffff; /* Ajusta el color del texto */
  text-align: end; /* Ajusta el alineamiento del texto */
}

.container-info-portada-p.scroll {
  transform: translate(0%);
  transition: transform 1s;
}

.h1{
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  width: auto;
  text-align: end;

}

.title-portada-2-p {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;

  width: auto;
  text-align: end;
}
.subtitulo-portada-p {
  margin-top: 10px;
  margin-bottom: 35px;
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 35px;
  text-align: end;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 16px;
}

.container-info-portada-p p {
  width: 500px;
  text-align: end;
  font-family: "Sedan SC", serif;
  font-style: normal;
  letter-spacing: 0.5px;
  font-size: 16px;
  margin-top: -10px;
}
.btn-portada-p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  background-color: #d9c9ba;
  color: #000000;
  border: none;
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
}

.span-p img {
  width: 20px;
  height: 20px;
}

.span-p {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uno-p {
  width: 90px;
  background-color: #ffffff;
  height: 1px;
}

.dos-p {
  width: 90px;
  background-color: #ffffff;
  height: 1px;
}

@media (max-width: 700px) {
  .portada-principal-p {
    background-image: url("../../assets/portadacelular.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .btn-portada-p {
    width: 50%;

    height: 30px;
    font-size: 15px;
    position: relative;
    bottom: 10px;
  }
  .span-p {
    width: 100%;
  }

  .uno-p {
    width: 40%;
  }
  .dos-p {
    width: 40%;
  }
  .container-info-portada-p {
    width: 100%;
    height: 700px;
    padding: 20px;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .title-portada-2-p {
    font-size: 29px;
    width: 100%;
    text-align: start;
  }

  .subtitulo-portada-p {
    font-size: 18px;
    width: 100%;
    text-align: start;
  }

  .container-info-portada-p p{
    width: 100%;
    text-align: start;
  }
}
