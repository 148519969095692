.card-diagonal {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 30px;
  background: #c7af96; /* Light blue color */
  width: 300px;
  height: 400px;
  color: white;
  padding: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.section-especilizaciones {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 50px;
  margin-bottom: 50px;
  margin: auto;

  width: 90%;
  background-color: #f5f5f5;
}

@media (max-width: 600px) {
  .section-especilizaciones{
    margin-bottom: 550px;
  }
}

.title-especializaciones {
  font-family: "Sedan SC", serif;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
}
.card-diagonal h1{
  font-family: "Sedan SC", serif;
  font-size: 16px;
  border-bottom: 2px solid white;
  padding-bottom: 5px;

  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;

}
.card-diagonal::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 280px;
  height: 460px;
  z-index: -1;
  background: #61717a;
  clip-path: polygon(-20px 0, 100% 0, 100% 80%, 0 100%);
  transform: translate(
    10px,
    -30px
  ); /* Adjust the position of the background card */
}


.card-diagonal p {
  margin: 0;
  font-family: "Sedan SC", serif;
}

.icon-card {
  width: 50px;
  height: 50px;

}
