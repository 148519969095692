.parallax {
  height: 500px;
  background-image: url("../../assets/estudio.webp");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}
.container-info-portada {
  display: flex;
  justify-content: center;
  z-index: 1000;
  margin-top: 10px;
  align-items: flex-end;
  width: 80%;
  height: 500px; /* Ajusta la altura del contenedor */
  flex-direction: column;
  /* Ajusta el color de fondo del contenedor */
  transform: translate(-100%);
  color: #ffffff; /* Ajusta el color del texto */
  text-align: end; /* Ajusta el alineamiento del texto */
}
.title-portada {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;

  width: auto;
  text-align: end;
}
.subtitulo-portada {
  margin-top: 10px;
  margin-bottom: 35px;
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-align: end;
  border-top: 1px solid #ffffff;
  padding-top: 10px;
}
.container-info-portada p {
  width: 500px;
  text-align: end;
  font-family: "Sedan SC", serif;
  font-style: normal;
  letter-spacing: 0.5px;
  font-size: 16px;
  margin-top: 20px;
}
.btn-portada {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  background-color: #d9c9ba;
  color: #000000;
  border: none;
  font-family: "Sedan SC", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
}

.span img {
  width: 20px;
  height: 20px;
}

.span {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uno {
  width: 90px;
  background-color: #ffffff;
  height: 1px;
}

.dos {
  width: 90px;
  background-color: #ffffff;
  height: 1px;
}
/* .content {
  height: 1000px;
  background-color: #f0f0f0;
  padding: 20px;
} */

@media (max-width: 600px) {
  .title-portada {
    width: 100%;
    font-size: 25px;
    margin-left: 80px;
    border: none;
  }
  .title-portada{
    font-size: 25px;
    text-align:center;
    width: 100%;

    position: relative;
    left: 10%;


  }
  .span,
  .subtitulo-portada {
    margin-left: 80px;
    font-size: 18px;
    text-align: start;
    width: 80%;
  }
  .btn-portada {
    display: none;
  }

  .container-info-portada {
    margin-bottom: 100px;
    width: 100%;
    height: 500px;
  }
  .parallax {
    height: 600px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

  }
}
