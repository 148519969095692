body {
  margin: -1px;
  overflow-x: hidden;
  width: 100%;
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}
#tm-8htd9 h2.go372176402 {
  display: none;
}

@media (max-width: 600px) {
  .footer-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
