/* color 1: #C3CEDA
color 2: #738FA7
color 3: #0C4160
color 4: #071330
*/

header {
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: center;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.495);
  z-index: 10;
  position: fixed; /* Posiciona el header de forma fija en la ventana */
  top: 0; /* Ajusta la posición superior del header */
}
.container-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 0 20px;
}

.logo {
  width: 120px;
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 5px;
}

.logo img {
  width: 100%;
  object-fit: contain;
}

nav {
  position: relative;
  left: 35px;
  top: 5px;
}

nav ul {
  list-style: none;
  margin: 15px;
  padding: 0;
}

nav li {
  display: inline-block;
  margin-left: 40px;
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

nav a {
  text-decoration: none;
  color: #ffffff;

  opacity: 1;
}

.btn-header-wapp {
  background-color: #755e51;
  color: #ffffff;
  text-align: center;
  position: relative;
  border-radius: 5px;
  padding: 5px 13px;
  border-color: transparent;
  text-decoration: none;
  font-family: "Sedan SC", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.btn-header-wapp img {
  width: 20px;
  margin-left: 10px;
  position: relative;
  top: 5px;
}

nav a:hover {
  color: #ffffff;
  border-bottom: #ffffff;
  border-bottom-width: 2px;
  padding-bottom: 5px;
  border-bottom-style: solid;
}

nav li:hover {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


@media (max-width: 600px){
  .header-ul{
    display: none;
  }

  .logo{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .logo img {
    width: 140px;
  }
  .container{
  height: 90px;
  justify-content: center;
  }
}

@media (max-width: 814px){
  .header-ul{
    display: none;
  }

  .logo{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .logo img {
    width: 140px;
  }
  .container{
  height: 90px;
  justify-content: center;
  }
}
