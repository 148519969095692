.cont-buttons-sociales{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 195px;
    right: 25px;
    margin: 0;
    z-index: 999;

}

.cont-buttons-sociales a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    margin: 5px;
    border-radius: 50%;
    background-color: rgb(103, 103, 103);
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    z-index: 999;
}

@media (max-width: 700px){
    .cont-buttons-sociales{
        bottom: 275px;
    }
}

@media (max-width: 550px){
    .cont-buttons-sociales{
        bottom: 275px;
        right: 5px;
        opacity: 0.5;
    }
}

@media (max-width: 435px){
    .cont-buttons-sociales{
        bottom: 275px;
        right: 35px;
        opacity: 0.5;
    }
}